import type { Nullable } from '@silverhand/essentials';
import classNames from 'classnames';
import type { TFuncKey } from 'i18next';

import ConnectIcon from '@/assets/icons/connect-icon.svg?react';

import DynamicT from '../DynamicT';

import styles from './index.module.scss';

export type Props = {
  readonly className?: string;
  readonly logo?: Nullable<string>;
  readonly thirdPartyLogo?: Nullable<string>;
  readonly headline?: TFuncKey;
  readonly headlineInterpolation?: Record<string, unknown>;
};

const DefaultLogo = () => {
  return (
    <svg
      aria-label="Contur wordmark"
      role="img"
      height="48"
      viewBox="0 0 511 256"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M503.2 160.9L488.6 137.6C491.4 136.1 493.8 134.4 495.8 132.5C497.8 130.6 499.4 128.6 500.5 126.4C501.3 124.9 501.9 123.3 502.3 121.8C502.7 120.3 503.1 118.9 503.3 117.7C503.5 116.5 503.7 115.5 503.7 114.7C503.7 113.9 503.8 113.4 503.8 113.2C503.8 108.1 502.6 103.6 500.2 99.6C497.8 95.6 494.5 92.5 490.3 90.3H490.4C487.2 88.8 484.4 87.9 481.8 87.6C479.2 87.3 477.8 87.1 477.4 87.1H457.8C456.5 87.1 455.4 87.6 454.5 88.5C453.6 89.4 453.2 90.5 453.2 91.7V163.6C453.2 164.8 453.6 165.9 454.5 166.8C455.4 167.7 456.5 168.2 457.8 168.2C459.1 168.2 460.2 167.7 461.2 166.8C462.1 165.9 462.6 164.8 462.6 163.6V139.6H478.4L495 166C495.9 167.3 497.4 168.2 499.1 168.2C501.8 168.2 503.9 166 503.9 163.4C503.9 162.4 503.6 161.6 503.2 160.9ZM477.5 130H462.7V96.5H477.5C478.3 96.5 479.2 96.6 480.2 96.7C481 96.9 482 97.1 483 97.3C484 97.6 485 98 486.1 98.5C491.6 101.4 494.3 106.2 494.3 113.1C494.3 113.2 494.3 113.5 494.2 114.1C494.2 114.7 494.1 115.4 493.9 116.3C493.7 117.2 493.5 118.2 493.2 119.2C492.9 120.2 492.5 121.3 491.9 122.3C490.5 124.8 488.6 126.7 486.2 128C483.9 129.3 481 130 477.5 130Z" />
      <path
        d="M24.8 140.1C21.3 136.1 18.4 132.2 15.9 128.3C7 142.3 6.6 151.6 6.5 156.1C8.2 187.2 38.5 211.9 84.3 249.4C89.8 228.7 91.1 223.8 96.2 203.9C31.1 147.4 31.1 147.4 24.8 140.1Z"
        fill="var(--color-brand-default)"
      />
      <path
        d="M6.40002 156.1V100.5C8.10002 70.2 34.1 48.6 85.9 5.80005C91.3 26.5 92.6 31.4001 97.6 51.2001C81.2 65.8001 64.2 78.5 47.7 93.2C42 98.3 33.6 106.2 25.7 115.2C7.20003 136.5 6.60002 150.2 6.40002 156.1Z"
        fill="var(--color-brand-default)"
      />
      <path d="M160.2 159.1C160.2 156.5 158.1 154.3 155.4 154.3C154.5 154.3 153.6 154.6 152.9 155C150.8 156.2 148.5 157.1 146.1 157.7C143.6 158.3 141 158.6 138.3 158.6C134 158.6 130 157.8 126.3 156.2C122.6 154.6 119.3 152.4 116.4 149.6C113.6 146.8 111.3 143.5 109.7 139.7C108 135.9 107.2 131.9 107.2 127.5C107.2 123.2 108 119.1 109.7 115.3C111.4 111.5 113.6 108.2 116.4 105.4C119.2 102.6 122.5 100.4 126.3 98.8C130.1 97.2 134.1 96.4 138.3 96.4C140.9 96.4 143.5 96.7 146.1 97.4C148.7 98.1 151 99 153.1 100.1C153.8 100.5 154.6 100.7 155.4 100.7C158 100.7 160.2 98.6 160.2 95.9C160.2 94 159 92.3 157.4 91.5C154.6 90.3 151.6 89.2 148.4 88.3C145 87.3 141.7 86.9 138.4 86.9C132.8 86.9 127.6 88 122.6 90.1C117.6 92.2 113.3 95.1 109.7 98.9C106 102.6 103.1 106.9 101 111.8C98.9 116.7 97.8 121.9 97.8 127.5C97.8 133.1 98.9 138.3 101 143.3C103.1 148.2 106 152.6 109.7 156.2C113.4 159.9 117.7 162.8 122.6 164.9C127.5 167 132.8 168.1 138.4 168.1C141.7 168.1 145.1 167.7 148.4 166.8C151.6 165.9 154.7 164.8 157.5 163.4C157.6 163.4 157.8 163.3 157.8 163.2C159.2 162.5 160.2 160.9 160.2 159.1Z" />
      <path d="M203.8 168.1C198.5 168.1 193.4 167 188.7 164.9C184 162.8 179.9 159.9 176.3 156.2C172.8 152.5 170 148.2 168 143.3C166 138.4 164.9 133.1 164.9 127.5C164.9 121.9 165.9 116.7 168 111.8C170 106.9 172.8 102.6 176.3 98.9C179.8 95.2 183.9 92.3 188.7 90.1C193.4 88 198.4 86.9 203.8 86.9C209.1 86.9 214.2 88 218.9 90.1C223.6 92.2 227.7 95.1 231.2 98.9C234.7 102.6 237.4 106.9 239.5 111.8C241.5 116.7 242.6 121.9 242.6 127.5C242.6 133.1 241.6 138.3 239.5 143.3C237.4 148.2 234.7 152.6 231.2 156.2C227.7 159.9 223.6 162.8 218.9 164.9C214.2 167.1 209.1 168.1 203.8 168.1ZM203.8 96.5C199.7 96.5 195.9 97.3 192.3 98.9C188.7 100.5 185.6 102.7 183 105.5C180.4 108.3 178.3 111.6 176.7 115.4C175.2 119.2 174.4 123.2 174.4 127.6C174.4 131.9 175.2 136 176.7 139.8C178.2 143.6 180.3 146.9 183 149.7C185.6 152.5 188.7 154.7 192.3 156.3C195.9 157.9 199.7 158.7 203.8 158.7C207.8 158.7 211.6 157.9 215.2 156.3C218.8 154.7 221.9 152.5 224.5 149.7C227.2 146.9 229.3 143.6 230.8 139.8C232.3 136 233.1 132 233.1 127.6C233.1 123.3 232.3 119.2 230.8 115.4C229.3 111.6 227.2 108.3 224.5 105.5C221.8 102.7 218.7 100.5 215.2 98.9C211.6 97.3 207.8 96.5 203.8 96.5Z" />
      <path d="M309.2 168.1C308.4 168.1 307.6 167.9 306.9 167.5C306.2 167.1 305.7 166.6 305.2 165.9L263.8 106.6V163.4C263.8 164.6 263.3 165.7 262.4 166.6C261.4 167.5 260.3 168 258.9 168C257.7 168 256.6 167.5 255.7 166.6C254.8 165.7 254.3 164.6 254.3 163.4V91.6C254.3 90.4 254.8 89.4 255.7 88.4C256.6 87.4 257.7 87 259.1 87C260 87 260.7 87.2 261.4 87.6C262.1 88 262.6 88.5 263.1 89.2L304.6 148.5V91.6C304.6 90.4 305.1 89.3 306 88.4C306.9 87.5 308 87 309.4 87C310.7 87 311.8 87.5 312.8 88.4C313.7 89.3 314.2 90.4 314.2 91.6V163.5C314.2 164.7 313.7 165.8 312.8 166.7C311.7 167.7 310.6 168.1 309.2 168.1Z" />
      <path d="M371.3 87C372.7 87 373.9 87.5 374.8 88.4C375.8 89.3 376.3 90.4 376.3 91.6C376.3 93 375.8 94.2 374.8 95.1C373.8 96 372.7 96.5 371.3 96.5H354.6V163.5C354.6 164.7 354.1 165.8 353.2 166.7C352.3 167.6 351.2 168.1 349.8 168.1C348.5 168.1 347.4 167.6 346.5 166.7C345.6 165.8 345.2 164.7 345.2 163.5V96.5H328.4C327.1 96.5 326 96 325 95.1C324.1 94.2 323.6 93 323.6 91.6C323.6 90.4 324.1 89.3 325 88.4C325.9 87.5 327 87 328.4 87H371.3Z" />
      <path d="M414 168.1C408.4 168.1 403.4 166.8 399.2 164.2C394.9 161.6 391.7 157.8 389.3 152.8C388.4 151.1 387.8 149.4 387.4 147.8C387 146.1 386.6 144.6 386.4 143.3C386.2 141.9 386 140.8 386 140C386 139.1 385.9 138.6 385.9 138.4V91.6C385.9 90.4 386.4 89.3 387.3 88.4C388.3 87.5 389.4 87 390.7 87C391.9 87 393 87.5 393.9 88.4C394.8 89.3 395.3 90.4 395.3 91.6V138.3C395.3 138.6 395.4 139.3 395.5 140.4C395.6 141.5 395.9 142.8 396.4 144.3C396.9 145.8 397.5 147.4 398.3 149.1C399.1 150.8 400.2 152.3 401.5 153.7C402.8 155.1 404.6 156.2 406.6 157.2C408.6 158.1 411.1 158.6 413.8 158.6C417.8 158.6 421.1 157.7 423.5 155.9C426 154.1 427.9 152 429.1 149.6C430.4 147.2 431.2 145 431.6 142.8C432 140.6 432.2 139.1 432.2 138.3V91.6C432.2 90.4 432.7 89.3 433.6 88.4C434.5 87.5 435.6 87 437 87C438.3 87 439.4 87.5 440.3 88.4C441.2 89.3 441.6 90.4 441.6 91.6V138.3C441.6 138.5 441.6 139.1 441.5 139.9C441.5 140.8 441.3 141.9 441.1 143.2C440.9 144.6 440.6 146.1 440.1 147.7C439.6 149.4 439 151 438.2 152.7C435.9 157.7 432.6 161.5 428.4 164.1C424.7 166.9 419.7 168.1 414 168.1Z" />
    </svg>
  );
};

const BrandingHeader = ({
  logo,
  thirdPartyLogo,
  headline,
  headlineInterpolation,
  className,
}: Props) => {
  const shouldShowLogo = Boolean(thirdPartyLogo ?? logo);
  const shouldConnectSvg = Boolean(thirdPartyLogo && logo);

  return (
    <div className={classNames(styles.container, className)}>
      {shouldShowLogo ? (
        <div className={styles.logoWrapper}>
          {thirdPartyLogo && (
            <img
              className={styles.logo}
              alt="third party logo"
              src={thirdPartyLogo}
              crossOrigin="anonymous"
            />
          )}
          {shouldConnectSvg && <ConnectIcon className={styles.connectIcon} />}
          {logo && (
            <img className={styles.logo} alt="app logo" src={logo} crossOrigin="anonymous" />
          )}
        </div>
      ) : (
        <div className={styles.logoWrapper}>
          <DefaultLogo />
        </div>
      )}

      {headline && (
        <div className={styles.headline}>
          <DynamicT forKey={headline} interpolation={headlineInterpolation} />
        </div>
      )}
    </div>
  );
};

export default BrandingHeader;
